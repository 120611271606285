
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import { searchWithUnapplied } from "@/api/patient.api";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import useFilters from "@/modules/common/useFilters";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  emptyListError?: string;
  items: [];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    encounterStatusCodes: [];
    patientPayers: [];
    facilities: [];
    paymentTypes: [];
    transactionTypes: [];
  };
}
export default defineComponent({
  name: "PatientUnappliedCredit",
  components: { PaginationUi },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const {
      orderList,
      getFilter,
    } = useFilters();

    orderList.value = [
      {
        name: "Patient ID",
        key: "accountId",
        isFilter: false,
        keyword: null,
        IsAdvancedOrderBy: false,
      },
      {
        name: "Name",
        key: "name",
        isFilter: false,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "DOB",
        key: "dob",
        isFilter: false,
        keyword: null,
        formType: "isDate",
      },
      {
        name: "Balance Due from Patient",
        key: "patientAccount.outstandingBalancePatient",
        isFilter: false,
        keyword: null,
        formType: "isNumber",
      },
      {
        name: "Unapplied Credit",
        key: "patientAccount.unappliedCredit",
        isFilter: false,
        keyword: null,
        formType: "isNumber",
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      keyword: "",
      orderBy: "name",
      IsAdvancedOrderBy: true,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        encounterStatusCodes: [],
        patientPayers: [],
        facilities: [],
        paymentTypes: [],
        transactionTypes: [],
      },
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "name";
      data.isDecr = false;
      data.IsAdvancedOrderBy = true;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      getSearchFromUrl();

      organizationId.value = getOrganization();
      setCurrentPageTitle("Patient Unapplied Credits");
      data.dict.paymentTypes = store.getters.allPaymentTypes;
      data.dict.transactionTypes = store.getters.allTransactionTypes;
      const accountId = route.query.accountId;
      let accountFilter = orderList.value.find((item) => {
        return item.key == "accountId";
      }) as any;
      if (accountId && accountFilter) {
        accountFilter.keyword = accountId;
      }
      await getAll();
    });

    async function selectFilter(header) {
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      putSearchToUrl();

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.value
            .filter((item) => {
              return !item.IsAdvancedOrderBy;
            })
            .map((item) => {
              return item.key;
            })
        },
        UnAppliedCreditOnly: true,
        GlobalSearch: data.keyword,
      };

      const res = await searchWithUnapplied(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      data.emptyListError = undefined;
      if (data.items.length == 0) {
        const currentFilters = orderList.value.filter((f) => {
          return (
            f.keyword != undefined && f.keyword !== "" && f.keyword != null
          );
        });
        if (currentFilters.length > 0) {
          data.emptyListError =
            "**No results match your filter criteria. Please clear all filters to view list";
        } else {
          data.emptyListError = "List is Empty";
        }
      }
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function selectItem(item) {
      const accountId = route.query.accountId;
      if (accountId) {
        router.push({
          path: "/patientPayment/" + item.id + "/",
          query: { patientId: item.patientId },
        });
      } else {
        router.push({ path: "/patientPayment/" + item.id + "/" });
      }
    }

    function refundCredits() {
      router.push({
        path: "/Payments/patientRefund/",
      });
    }

    function getSearchFromUrl() {
      if (route.query.globalSearch) {
        data.keyword = route.query.globalSearch as string;
      }
    }

    function putSearchToUrl() {
      let currentRoute = route.fullPath;
      if (data.keyword) {
        router.replace({
          path: currentRoute,
          query: { globalSearch: data.keyword }
        });
      }
      else {
        router.replace({
          path: currentRoute
        });
      }
    }

    return {
      organizationId,
      clearSearch,
      data,
      selectItem,
      orderList,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      refundCredits,
      pageSizeChanged,
    };
  },
});
